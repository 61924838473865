import type {PropsWithChildren} from 'react';
import {Button} from 'react-bootstrap';

import svg from './github.svg';

export interface CtaButtonProps {
  upgrade?: boolean;
  variant?: 'light' | 'dark';
}

export const CtaButton = ({
  upgrade = false,
  variant,
}: PropsWithChildren<CtaButtonProps>) => (
  <Button
    href="https://github.com/marketplace/check-run-reporter"
    variant={variant === 'dark' ? 'dark' : 'primary'}
  >
    {upgrade ? 'Upgrade with GitHub' : 'Sign up with GitHub'}
    <img alt="" src={svg} />
  </Button>
);
