import {Section, SectionHeading} from '@code-like-a-carpenter/components';
import type {PropsWithChildren} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

export interface LandingPageSectionProps {
  title: string;
}

export const LandingPageSection = ({
  children,
  title,
}: PropsWithChildren<LandingPageSectionProps>) => (
  <Section>
    <div className="landing-page-section">
      <Container>
        <Row>
          <Col>
            <SectionHeading>{title}</SectionHeading>
            <Section>{children}</Section>
          </Col>
        </Row>
      </Container>
    </div>
  </Section>
);
