import {Code, Section, SectionHeading} from '@code-like-a-carpenter/components';
import {Col, Row} from 'react-bootstrap';

import failure from '../../public/failure.png';
import failure2 from '../../public/failure@2x.png';
import failure3 from '../../public/failure@3x.png';
import styleFailure from '../../public/style-failure.png';
import styleFailure2 from '../../public/style-failure@2x.png';
import styleFailure3 from '../../public/style-failure@3x.png';
import {CtaButton} from '../components/atoms/cta-button/cta-button';
import {Example} from '../components/atoms/example';
import {LandingPageSection} from '../components/molecules/landing-page-section';
import {LandingPageHero} from '../components/organisms/landing-page-hero';
import {PricingCard} from '../components/organisms/pricing-card';

export default function LandingPage() {
  return (
    <Section>
      <LandingPageHero />
      <LandingPageSection title="How it works">
        <ol>
          <li>
            <p>You run your tests</p>
            <Code>
              npm test -- --reporter=junit -o ./reports/junit/test.xml
            </Code>
          </li>

          <li>
            <p>You upload your results to Check Run Reporter</p>
            <Example
              report="./reports/junit/test.xml"
              token="88888888-4444-4444-4444-aaaaaaaaaaaa"
            />
          </li>

          <li>
            <p>Check Run Reporter sends your results to GitHub</p>
          </li>
        </ol>
      </LandingPageSection>
      <LandingPageSection title="Features">
        <Row>
          <Col md={6}>
            <figure className="figure">
              <img
                alt="A screenshot of a style failure rendered directly in a GitHub Pull Request diff"
                className="figure-img img-fluid"
                src={styleFailure}
                srcSet={`${styleFailure}, ${styleFailure2}, ${styleFailure3}`}
              />
              <figcaption className="figure-caption">
                See your style violations right where they happened
              </figcaption>
            </figure>
          </Col>

          <Col md={6}>
            <figure className="figure">
              <img
                alt="A screenshot of a test failure rendered on the Check Run's details screen"
                className="figure-img img-fluid"
                src={failure}
                srcSet={`${failure}, ${failure2}, ${failure3}`}
              />
              <figcaption className="figure-caption">
                See your test failures without leaving GitHub
              </figcaption>
            </figure>
          </Col>
        </Row>
      </LandingPageSection>
      <LandingPageSection title="Pricing">
        <Row xs={1} sm={3} className="g-3">
          <Col>
            <PricingCard
              title="Open Source"
              amount="Free!"
              details={[
                'Unlimited public repositories',
                'Ephemeral results',
                '50000 reports',
                '1GB of reports',
              ]}
            />
          </Col>
          <Col>
            <PricingCard
              title="Ephemeral"
              amount="$19"
              period="per month"
              details={[
                'Unlimited public & private repositories',
                'See your results without leaving GitHub',
                '50000 reports',
                '1GB of reports',
              ]}
            />
          </Col>
          <Col>
            <PricingCard
              title="Persistent"
              amount="$59"
              period="per month"
              details={[
                'Unlimited public & private repositories',
                'Test History',
                '2 week retension',
                '50000 reports',
                '1GB of reports',
              ]}
            />
          </Col>
        </Row>
      </LandingPageSection>
      <LandingPageSection title="Why Check Run Reporter?">
        <p>
          I created check-run-reporter.com after years of being frustrated by
          how CI services present test results. Once a project hits a certain
          size, scrolling through logs just isn't a viable way to see what
          failed. GitHub provides a powerful way to present test results no
          matter what CI service you're using; as long as you can produce JUnit,
          check-run-reporter can render your results.
        </p>

        <Section>
          <SectionHeading>Supported Formats</SectionHeading>
          Check Run Reporter supports the following report formats, with more on
          the way.
          <ul>
            <li>Checkstyle</li>
            <li>JUnit</li>
            <li>ESLint JUnit</li>
            <li>ESLint JSON</li>
            <li>ESLint JSON with Metadata</li>
            <li>SwiftLint JSON</li>
            <li>Typescript CLI Output</li>
          </ul>
        </Section>
        <CtaButton />
      </LandingPageSection>
    </Section>
  );
}
