import {Container, Row} from 'react-bootstrap';

import {CtaButton} from '../atoms/cta-button';
import {Logo} from '../atoms/logo';

export const LandingPageHero = () => (
  <div className="landing-page-hero">
    <Container>
      <Row>
        <div className="col-md-2 offset-lg-1 col-lg-2 d-none d-md-block">
          <Logo />
        </div>
        <div className="offset-md-1 col-md-9 offset-lg-0 col-lg-9 col-12">
          <h1 className="display-4">Check Run Reporter</h1>
          <p className="lead">
            The reporting tool you wish your CI service had built.
          </p>

          <p>
            You and your team don't have time to dig through your CI service to
            figure out what test failed or what code style rule you violated.
            With a small tweak to your CI scripts, Check Run Reporter shows
            failures right on your GitHub pull requests.
          </p>
          <p>
            Continuous integration is critical to delivering high quality
            products and services in a timely fashion. Though most CI services
            do a fantastic job of running your builds, tests, and deployments,
            few do a good job of telling you which of your tests failed or why.
          </p>
          <p>
            Check Run Reporter delivers your test and style results directly to
            GitHub as check runs, so you can spend less time digging through
            poorly formatted CI logs.
          </p>

          <CtaButton variant="dark" />
        </div>
      </Row>
    </Container>
  </div>
);
