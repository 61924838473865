import {Card} from '@code-like-a-carpenter/components';
import {Children} from 'react';

export interface PricingCardProps {
  title: string;
  amount: string;
  period?: string;
  details: string | string[];
}

export const PricingCard = ({
  title,
  amount,
  period,
  details,
}: PricingCardProps) => (
  <Card className="card--pricing" headerSlot={title}>
    <div className="card__amount">{amount}</div>
    <div className="card__period">{period ? period : ' '}</div>
    {Children.map(details, (detail) => (
      <div className="card__details">{detail}</div>
    ))}
  </Card>
);
